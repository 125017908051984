import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"

// import Lectures from '../../components/lectures'
// import Speakers from "../../components/speakers"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const IndexPage = ({ data }) => {
  const [showOverlay, setShowOverlay] = useState(true)

  useEffect(() => {
    window.wistiaInit = function(W) {
      var s = W.api("intro_video")
      s.bind("play", function() {
        setShowOverlay(false)
      })
    }
  }, [])

  return (
    <Layout page="sanfrancisco">
      <Helmet>
        <script src="//fast.wistia.com/assets/external/E-v1.js" async></script>
      </Helmet>
      <SEO title="ForwardJS San Francisco 2020" />
      <section
        className="hero web-hero large-section thanks has-banner"
        style={{ backgroundImage: `url(${data.bgImage.publicURL})` }}
      >
        <div className="fadeOverlay"></div>
        <div className="inner fadeIn" data-wow-duration="1s">
          <h1>Sept 21-23 2020 in <span className="striked">SF</span> The Interweb</h1>

          <h3>Due to the COVID-19 situation we have decided to make ForwardJS San Francisco a virtual event, providing five days of workshops and talks to registrants from the comfort of their browser. Best part is, the talk sessions will be FREE.<br /><br /></h3>

          <h2>
            <a
              className="what-is-forward"
              href="http://bit.ly/forward2020cfp"
              target="_blank"
              rel="noopener noreferrer"
            >
              Apply to Speak
            </a>
            <br />
            <br />
            <a
              className="what-is-forward"
              href="http://bit.ly/forwardjs-sf-sponsor"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sponsor ForwardJS San Francisco
            </a>
          </h2>
        </div>
      </section>
      <section id="this-is-forward" className="video-section large-section">
        {showOverlay ? (
          <h3 className="video-overlay-header">This is Forward.</h3>
        ) : null}
        <div className="video-wrapper">
          <div
            id="intro_video"
            className="video wistia_embed wistia_async_ba84q1a3n8 videoFoam=true"
          >
            &nbsp;
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    bgImage: file(relativePath: { eq: "bg-image-masks.jpg" }) {
      publicURL
    }
    workshops: allMarkdownRemark(
      filter: {
        frontmatter: { conference: { eq: "sanfrancisco" }, type: { eq: "workshop" } }
      }
    ) {
      group(field: frontmatter___day) {
        edges {
          node {
            id
            html
            frontmatter {
              address
              end
              image {
                publicURL
              }
              speaker
              title
              start
            }
          }
        }
        fieldValue
      }
    }
    speakers: allMarkdownRemark(
      filter: {
        frontmatter: { type: { eq: "talk" }, conference: { eq: "sanfrancisco" } }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            image {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            type
            company
            github
            remoteImage
            position
            title
            speaker
            tracks
            twitter
          }
          html
        }
      }
    }
  }
`
